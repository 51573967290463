var is_mobile = window.matchMedia('all and (max-width: 1230px)').matches;

$(function () {

	//Кастомизация чекбоксов
	init_checkbox_style();
	init_radio_style();


	$(window).on('resize', function () {
		is_mobile = window.matchMedia('all and (max-width: 1230px)').matches;
	});



});


function init_radio_style() {
	$('input.js-radio').each(function () {
		if (!$(this).parent().is('.radio_box')) {
			var placeholder = $(this).attr('placeholder');

			$(this).wrap('<label class="radio_box"></label>');
			$(this).after('<span class="radio_box__item"></span><span class="radio_box__text">' + placeholder + '</span>');
		}

		if ($(this).prop('checked')) {
			$(this).parent('.radio_box').addClass('checked');
		} else {
			$(this).parent('.radio_box').removeClass('checked');
		}
	});
};


function init_checkbox_style() {
	$('input.js-checkbox').each(function () {
		if (!$(this).parent().is('.check_box')) {
			var placeholder = $(this).attr('placeholder');

			$(this).wrap('<label class="check_box"></label>');
			$(this).after('<span class="check_box__item"></span><span class="check_box__text">' + placeholder + '</span>');
		}

		if ($(this).prop('checked')) {
			$(this).parent('.check_box').addClass('checked');
		} else {
			$(this).parent('.check_box').removeClass('checked');
		}
	});
};